/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
            summary2
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="flex items-center mb-4 mt-8">
      <StaticImage
        className="rounded-full"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/maruko.png"
        width={92}
        height={92}
        quality={100}
        alt="Profile picture"
        imgClassName="rounded-full"
        // imgStyle={{ borderRadius: "100%" }}
      />
      {author?.name && (
        <div className="px-4">
          <h1 className="text-lg">{author.name}</h1>
          <p className="text-xs text-gray-400">{author?.summary || null}</p>
          <p className="text-xs text-gray-400 pt-2">{author?.summary2 || null}</p>
        </div>
      )}
    </div>
  )
}

export default Bio
